@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  flex: 1;
  position: relative; /* Required for absolute positioning of inner content */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
}

.header-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-left: 20px;
}

h1,h2,h3 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  color: #000;
}

h4,h5,h6 {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  color: #000;
}

p {

  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #000;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  background-color: #fbf0ec;
}

.App-header {
  background-color: #E2725B;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.navigation {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.prev-button,
.next-button {
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
  margin: 0 10px;
  
}

.prev-button:hover,
.next-button:hover {
  background-color: #555;
}

  .navigation-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .dot {
    width: 15px;
    height: 15px;
    background-color: #8c8a8a;
    border-radius: 50%;
    border: none;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .dot.active {
    background-color: #1D3BCF;
  }

@media only screen and (max-width: 767px) {
  .flex-row {
    flex-direction: column;
  }

}
/*med*/
@media only screen and (min-width: 768px) and (max-width: 1023px) {

}

/*lrg*/
@media only screen and (min-width: 1024px) {

}